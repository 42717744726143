import React from 'react';
import styled from 'styled-components';

import Container from 'components/common/container';
import { Heading1 } from 'components/common/text';

const Wrapper = styled.div`
  padding: 80px 0;
  text-align: center;
`;

export default function Custom404() {
  return (
    <Container>
      <Wrapper>
        <Heading1>Page Not Found</Heading1>
        <p>
          Sorry, we couldn&apos;t find the page you requested. Please check the
          URL and try again.
        </p>
      </Wrapper>
    </Container>
  );
}

export async function getStaticProps() {
  return {
    revalidate: 5,
    props: {},
  };
}
